import ReportsLayout from '../../../hocs/ReportsLayout';
import PaymentsReportLastUpdateAtFooter from '../components/PaymentsReportLastUpdateAtFooter';
import HourlySalesFilter from '../views/HourlySalesFilter';
import HourlySalesHeader from '../views/HourlySalesHeader';
import HourlySalesSummary from '../views/HourlySalesSummary';

function HourlySalesSummaryPage() {
  return (
    <ReportsLayout
      title="Hourly Sales"
      ContentComponent={HourlySalesSummary}
      HeaderComponent={HourlySalesHeader}
      FilterComponent={HourlySalesFilter}
      FooterComponent={PaymentsReportLastUpdateAtFooter}
    />
  );
}

export default HourlySalesSummaryPage;
