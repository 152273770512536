import Box from '@mui/material/Box';

import AppCard from '../../../components/AppCard';
import { useAppSelector } from '../../../hooks';
import { selectAuth } from '../../../redux/selectors/authSelectors';
import { selectHourlySales } from '../../../redux/selectors/hourlySalesSelectors';
import { decimalCalculations } from '../../../utils';

function HourlySalesFilter() {
  const { totalCollected, totalAvgHourlySale } =
    useAppSelector(selectHourlySales);

  const { restaurant } = useAppSelector(selectAuth);

  return (
    <Box sx={{ display: 'flex', gap: 2 }}>
      <AppCard
        title="Total Sales"
        value={decimalCalculations(totalCollected).toMoney(
          restaurant?.posCurrencySymbol ?? restaurant?.currency ?? 'SGD ',
        )}
      />
      <AppCard
        title="Avg Hourly Sales"
        value={decimalCalculations(totalAvgHourlySale).toMoney(
          restaurant?.posCurrencySymbol ?? restaurant?.currency ?? 'SGD ',
        )}
      />
    </Box>
  );
}

export default HourlySalesFilter;
