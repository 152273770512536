import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { ResponsiveHeatMapCanvas } from '@nivo/heatmap';
import { useCallback, useEffect, useMemo } from 'react';

import AppEmptyTable from '../../../components/AppEmptyTable';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { updatePaymentsReportLastUpdated } from '../../../redux/AppSlice';
import { selectApp } from '../../../redux/selectors/appSelectors';
import { selectAuth } from '../../../redux/selectors/authSelectors';
import { selectHourlySales } from '../../../redux/selectors/hourlySalesSelectors';
import { fontFamily } from '../../../theme';
import { Colors } from '../../../theme/colors';
import {
  addColumnsToHourlySalesSummary,
  fetchHourlySalesSummary,
} from '../redux/hourlySalesSummarySlice';
import {
  formatHourlySalesData,
  getFormattedHourlySalesColumns,
} from '../utils/formatting.utils';

const HourlySalesSummary = () => {
  const dispatch = useAppDispatch();

  const { refreshing, hourlySalesData, lastUpdated } =
    useAppSelector(selectHourlySales);

  const { reportStartDate, reportEndDate } = useAppSelector(selectApp);

  const { restaurant } = useAppSelector(selectAuth);

  const getData = useCallback(async () => {
    await dispatch(
      fetchHourlySalesSummary({
        date_from: reportStartDate,
        date_to: reportEndDate,
        offset: restaurant?.timeZoneValue,
      }),
    );
  }, [dispatch, reportEndDate, reportStartDate, restaurant?.timeZoneValue]);

  useEffect(() => {
    dispatch(updatePaymentsReportLastUpdated(lastUpdated));
  }, [dispatch, lastUpdated]);

  useEffect(() => {
    getData();
  }, [dispatch, getData]);

  useEffect(() => {
    dispatch(
      addColumnsToHourlySalesSummary(
        getFormattedHourlySalesColumns(hourlySalesData),
      ),
    );
  }, [dispatch, hourlySalesData]);

  const rowData = useMemo(() => {
    return formatHourlySalesData(hourlySalesData);
  }, [hourlySalesData]);

  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
      }}>
      {refreshing && (
        <Backdrop
          open={true}
          sx={{
            position: 'absolute',
            zIndex: 999,
            backgroundColor: 'transparent',
          }}
          data-testid="loading-indicator">
          <CircularProgress size="25px" thickness={5} />
        </Backdrop>
      )}
      <Box sx={{ minHeight: '60vh', opacity: refreshing ? 0.5 : 1 }}>
        {hourlySalesData.length === 0 ? (
          <Box
            sx={{
              display: 'flex',
              borderRadius: '8px',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              minHeight: '300px',
              background: Colors.backgroundLight,
            }}>
            <AppEmptyTable />
          </Box>
        ) : (
          <Box sx={{ height: '600px', width: '100%' }}>
            <ResponsiveHeatMapCanvas
              data={rowData}
              margin={{ top: 50, right: 90, bottom: 20, left: 90 }}
              ariaLabel={fontFamily}
              valueFormat=" >-,.2f"
              axisTop={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendOffset: 46,
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legendPosition: 'middle',
                legendOffset: 40,
              }}
              axisRight={null}
              colors={{
                type: 'diverging',
                scheme: 'oranges',
                divergeAt: 0.9,
              }}
              borderWidth={2}
              borderColor={Colors.white}
              enableLabels={true}
              labelTextColor={Colors.black}
              annotations={[]}
              motionConfig="slow"
              animate={true}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default HourlySalesSummary;
